@import '~bootstrap/scss/bootstrap';
@import '~@fontsource/nunito/400.css';
@import '~@fontsource/nunito/700.css';

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaaaaa;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Custom styles

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background: rgb(249, 249, 249);
  overflow: auto;
  font-family: 'Nunito';

  &.noScroll {
    overflow: hidden;
  }
}

html {
  height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

#root {
  height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.landing-right-col {
  background-color: #333333;
}

// Typography

body {
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  color: #000000;
}

a {
  color: #000000;

  &:hover {
    text-decoration: underline;
    color: #000000;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
}

// Tinder card styles

.swipe {
  position: absolute;
}

.cardContainer {
  margin-top: 25px;
  width: 80vw;
  max-width: 300px;
  height: 260px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.tinderCard {
  position: relative;
  background-color: #fff;
  width: 80vw;
  max-width: 300px;
  height: 260px;
  // box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.3);
  // border: 1px solid #afafaf;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  padding: 15px;
  user-select: none;

  h3 {
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    line-height: 2.3rem;
  }
}

.tinderCardInner {
  width: 100%;
  border: 1px solid #f2f3f5;
  border-radius: 10px;
  padding: 15px;
  height: 100%;
  overflow-wrap: break-word;
}

.cardContent {
  width: 100%;
  height: 100%;
}

// Toasts

.Toastify {
  * {
    font-family: 'Nunito';
  }

  .Toastify__toast--success {
    background: #46d191;
  }

  .Toastify__toast--info {
    background: #ffc34e;
  }

  .Toastify__toast--error {
    background: #fe7272;
  }

  .Toastify__toast--warning {
    background: #fba139;
  }
}

// Forms

.input-group {
  .form-control.is-valid {
    // background-image: none;
  }
}

// Algolia

.ais-SearchBox {
  margin-top: 1rem;

  input {
    width: 100%;
    border-radius: 0.75rem;
    padding: 0.3rem 0.3rem 0.3rem 1.7rem;
    border: none;
    background: white;
  }
}

.ais-SearchBox-form {
  display: block;
  position: relative;
}

.ais-SearchBox-submit {
  position: absolute;
  left: 0.3rem;
  background: none;
  width: 20px;
  height: 20px;
  top: 50%;
  appearance: none;
  border: none;
  transform: translateY(-50%);
}

.ais-SearchBox-submitIcon {
  height: 14px;
  width: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.ais-SearchBox-reset {
  display: none;
}

.ais-Hits {
  position: relative;
  top: 0;
}

.ais-Hits-list {
  max-height: 50vh;
  position: absolute;
  display: block;
  z-index: 100;
  padding: 1rem 1.5rem 0;
  margin: 0 -0.5rem;
  list-style-type: none;
  background: #f9f9f9;
  font-weight: bold;
  font-size: 1rem;
  overflow-y: scroll;
}

.ais-Hits-item {
  margin-bottom: 1rem;
}

.form-group {
  .form-label:not(.form-check-label) {
    font-size: 14px;
    margin-top: 0.5rem;
  }
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-down' class='svg-inline--fa fa-chevron-down fa-w-14 ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='currentColor' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'></path></svg>");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding: 1rem;
  padding-right: 35px !important;
}

.form-control {
  border-radius: 10px;
  padding: 0.375rem 0.75rem;
  height: calc(1.5em + 0.75rem + 5px);
}

.form-check > * {
  cursor: pointer;
}

.form-check-input {
  visibility: hidden;

  &:after {
    visibility: visible;
  }

  &:before {
    visibility: visible;
  }
}

input,
textarea,
.form-control {
  border-color: #afafaf;
}

input[type='checkbox'] {
  border-radius: 5px;
}

input[type='checkbox']::before {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 3px;
  border: solid 1.5px #e4e4e4;
  background-color: #e4e4e4;
}

input[type='checkbox']::after {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 1px;
  background-color: #e4e4e4;
  margin-left: 3px;
  margin-top: 3px;
}

input[type='checkbox']:checked:before {
  border: solid 2px #ffc34e;
  background-color: #fff;
}

input[type='checkbox']:checked:after {
  background-color: #ffc34e;
}

.modal-content {
  overflow: hidden;
  border-radius: 10px;
  border: none;
}

.modal-title {
  font-weight: 600;
}

label {
  color: #1a1a1a;
}

.no-select,
label {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #afafaf !important;
  opacity: 1; /* Firefox */
  font-size: 16px !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #afafaf !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #afafaf !important;
}

:focus-visible,
:focus {
  outline: none !important;
}

.loader {
  color: #373737;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  @-webkit-keyframes load6 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
        -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
        -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
        -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
        -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
        -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
        -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes load6 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
        -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
        -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
        -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
        -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
        -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
        -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @-webkit-keyframes round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.rc-pagination {
  list-style-type: none;
  padding: 0;
  width: 100%;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  li a {
    display: block;
  }

  li a,
  .rc-pagination-prev,
  .rc-pagination-next {
    padding: 0.25rem 0.75rem;
  }

  li.rc-pagination-item-active {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000;
  }
}

.ln-dropdown-wrapper {
  position: relative;

  &.hidden .pointer {
    display: none;
  }

  &.confirm .options-wrapper {
    padding: 1rem;
  }

  .options > button:first-child {
    margin-right: 10px;
  }

  &.hidden {
    overflow: hidden;
  }

  &.show > button {
    filter: brightness(0.9);
  }

  .title {
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 15px;
  }

  .options-wrapper {
    position: absolute;
    top: 120%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
    transition: ease-out 0.2s;
    transform-origin: left top;
    z-index: 200;
    overflow: hidden;

    &.show {
      transform: scaleY(1);
    }

    &.hidden {
      transform: scaleY(0);
    }

    &.left {
      right: 0;
    }

    &.center {
      right: -250%;
    }

    &.right {
      left: 0;
    }
  }

  .options-wrapper .options > * {
    white-space: nowrap;
    padding: 11px 15px;
    cursor: pointer;

    &:hover {
      // background-color: rgba(0, 0, 0, 0.1);
      filter: contrast(0.5);
    }
  }
}

.switch.btn {
  border-radius: 10px;
  padding: 0 50px;
  // height: 38px;
  .switch-handle {
    border-radius: 10px;
    width: 38px;
  }
}

.ln-rating {
  border-radius: 10px;
}

.ln-btn {
  cursor: pointer;
  width: max-content;
  white-space: nowrap;
  height: 40px;
  border: none !important;
  font-weight: 600;
  padding: 0 15px;
  border-radius: 10px !important;
  font-size: 16px;
  display: flex;
  align-items: center;
  outline: none;
  transition: 0.3s ease-out;
  background-color: transparent;

  span.ln-icon {
    display: flex;
  }

  &:hover {
    text-decoration: none;
  }

  &:active,
  &:focus {
    background: inherit;
  }

  &:disabled,
  &[disabled] {
    background-color: #aaaaaa !important;
  }

  &.ln-icon-btn {
    &.dropdown-toggle:after {
      display: none;
    }

    padding: 0.5rem;
    width: 40px;
    justify-content: center;
  }

  &.ln-btn-icon-txt {
    & > * {
      margin: 0;
    }

    & *:first-child {
      margin-right: 3px;
    }
  }

  &.negative {
    color: #fc5a5a !important;
  }

  &.ln-icon-btn > * {
    margin: 0;
  }

  

  > * {
    margin: 0 0.3rem;
  }

  &.primary {
    background-color: #373737;
    color: #fff;
  }

  &.default {
    background-color: #ebebeb !important;
    color: #1d1d1d;
  }

  &.danger {
    color: #fff;
    background-color: #fc5a5a;
  }

  &.full-width {
    width: 100%;
    justify-content: center;
  }

  &.small {
    height: initial;
    padding: 0px;
    font-size: 0.75rem;
    height: initial;
    padding: 0.4rem 0.70rem;
  }

}

@media (hover: hover) and (pointer: fine) {
  .ln-btn:hover {
    filter: brightness(0.9) !important;
  }
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-radius: 10px;
}

.rc-pagination li.rc-pagination-item-active {
  border-radius: 10px;
}

.form-control:disabled,
.form-control[readonly] {
  background: initial;
  border: none;
}

.ais-Hits {
  background: #fff;
  .ais-Hits-list {
    position: static;
    background: #fff;
  }
}

// Bootstrap Table Pagination
.react-bootstrap-table-pagination-list {
  .pagination {
    .page-link {
      font-size: 0.875rem;
      line-height: 0.875rem;
      padding: 0.5rem 0.75rem;
    }
  }
}

.sidebar-bg {
  background-color: #eeeded ;
  border-radius: 20px;;
}

.flex-spacer {
  flex: 1;
}

.self-center {
  align-self: center
}
