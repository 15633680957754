.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.horizontal-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.ln-radio-label {
  display: flex;
  align-items: center;
}

.mg-t {
  margin-top: 1rem;
}

.mg-b {
  margin-bottom: 1rem;
}

.mg-l {
  margin-left: 1rem;
}

.mg-r {
  margin-right: 1rem;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.children-mg-l > * {
  margin-left: 1rem !important;
}

.children-mg-r > *:not(:last-child) {
  margin-right: 1rem !important;
}

.children-mg-t > * {
  margin-top: 1rem !important;
}

.children-mg-b > * {
  margin-bottom: 1rem !important;
}

.form_radio {
  margin-bottom: 10px;
}

input[type='radio'] {
  /* display: none; */
}

input[type='radio']:after {
  content: '';
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: solid 1.5px #e4e4e4;
  border-radius: 3px;
  position: absolute;
  z-index: 10;
}

input[type='radio']:before {
  content: '';
  top: 2.5px;
  left: 2.5px;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  position: absolute;
  z-index: 11;
}

input[type='radio']:checked:after {
  border-color: #ffc34e;
}

input[type='radio']:checked:before {
  background-color: #ffc34e;
}

/* Checked */
input[type='radio']:checked + label:before {
  /* background: url(/img/radio-2.png) 0 0 no-repeat; */
}

/* Disabled */
input[type='radio']:disabled + label:before {
  filter: grayscale(100%);
}

/* #app {
  margin-top: 40px;
}

#progressInput {
  margin: 20px auto;
  width: 30%;
} */

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: black;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 1rem;
  font-weight: bold;
  fill: black;
}
